// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-columnist-js": () => import("./../src/templates/columnist.js" /* webpackChunkName: "component---src-templates-columnist-js" */),
  "component---src-templates-article-index-js": () => import("./../src/templates/article-index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-columnist-index-js": () => import("./../src/pages/columnist/index.js" /* webpackChunkName: "component---src-pages-columnist-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-daily-brief-index-js": () => import("./../src/pages/daily-brief/index.js" /* webpackChunkName: "component---src-pages-daily-brief-index-js" */),
  "component---src-pages-tag-index-js": () => import("./../src/pages/tag/index.js" /* webpackChunkName: "component---src-pages-tag-index-js" */),
  "component---src-pages-trending-index-js": () => import("./../src/pages/trending/index.js" /* webpackChunkName: "component---src-pages-trending-index-js" */)
}

